<template>
  <svg
    viewBox="0 0 24 24">
    <path
      d="M12,0 C18.6274,0 24,5.37258 24,12 C24,18.6274 18.6274,24 12,24 C5.37258,24 0,18.6274
      0,12 C0,5.37258 5.37258,0 12,0 Z M16.5961941,5.98959236 L12.0007071,10.5850793
      L7.40380592,5.98959236 L5.98959236,7.40380592 L10.5864935,11.9992929 L5.98959236,16.5961941
      L7.40380592,18.0104076 L12,13.4142136 L16.5961941,18.0104076 L18.0104076,16.5961941
      L13.4142136,12 L18.0104076,7.40380592 L16.5961941,5.98959236 Z">
    </path>
  </svg>
</template>
